.formHeader {
  display: flex;
  justify-content: space-between;
}
.formButtonGroup {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.btn {
  margin: auto !important;
}

.modalTitle {
  font-size: 25px;
  color: #203f80;
  width: auto;
}

.modalBody {
  display: flex;
}

.buttonsGroup {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}

.listGroupTitle {
  font-size: 25px;
  color: #203f80;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  border-bottom: 2px solid grey;
  padding-bottom: 10px;
  font-weight: bold;
}

.drawer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.email-control {
  width: 380px;
}
