.drawer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 500px;
}

.title {
  font-size: 25px;
  color: #203f80;
  padding-bottom: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.MuiTableRow-root {
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.MuiTableCell-root {
  border: none !important;
}

.btn-drawer {
  display: flex !important;
  align-items: center !important;
  align-self: center;
  gap:10px;
  width: fit-content;
}
