/* Customize bootstrap */
$theme-colors: (
  "primary": #203f80,
  "warning": #e9725b,
);

@import "~bootstrap/scss/bootstrap";

/* App styles */
@import "./AppConstants.scss";

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: $base-text-color;

  background-image: url("texture.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.home-layout {
  position: fixed;
  top: 130px;
  left: 0;

  width: 100%;
  height: calc(100% - 130px);

  z-index: 1;

  background-image: url("homeBg.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.home-content {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: calc(100% - 130px);
  z-index: 1;
}
.cookies-consent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.cookies-consent-message {
  text-align: center;
}
a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
.home-title {
  display: block;
  margin-right: 100px;
  margin-left: 100px;
}
.home-buttons {
  display: flex;
  gap: 20px;
  place-items: flex-end;
  position: absolute;
  bottom: 100px;
}
.home-button {
  display: block;
  background-color: #e9725b;
  border: none;
  font-size: 22px !important;
  padding: 15px;
  width: 900px;
  white-space: nowrap;
}

.padding-button {
  padding-right: 120px;
  padding-left: 120px;
}

/*
 * Titles
 */
.page-title {
  font-size: 36px;
  color: $primary-color;

  text-transform: uppercase;
}

.page-subtitle {
  font-size: 16px;
  color: $primary-color;

  text-transform: uppercase;
}

.contract__title {
  font-size: 22px;
}

h3 {
  font-size: 22px;
}

.alerts {
  position: fixed;
  top: 0;
  left: 0;

  width: calc(100% + 30px);
  z-index: 5000;
}

.logo-link {
  color: #ffffff;

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
}

.flagIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 10px;
  vertical-align: middle;
  border-radius: 50%;
}
.flags-wrapper {
  position: absolute;
  right: 20px;
  top: -5px;
  z-index: 1;
}
.flags-wrapper {
  @media screen and (max-width: 991px) {
    margin-bottom: 15px;
  }
}

/* btn */
.btn {
  //padding: 2px 5px;
  font-size: 12px;
}

#root {
  > .container {
    padding-bottom: 25px;
    padding-top: 25px;
  }
}

table {
  margin-top: 15px;
}

table .btn-outline-primary,
.btn-outline-primary.custom {
  border: none !important;
  font-weight: normal !important;
  font-size: 14px;
}

.modal-open .modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border-radius: 25px;
  padding: 25px;
  width: fit-content !important;
}

.modal-title {
  color: #203f80;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
}

.modal-header {
  border-bottom: 2px solid #808080;
}

.modal-footer {
  justify-content: center;
  border-top: none;
}

table {
  text-align: center;
}

table thead tr {
  text-align: center;
  background-color: #b3b2b2;
  color: #ffffff;
}

.form-group {
  position: relative;
}

input[data-name="itineraryPorts_departure"],
input[data-name="itineraryPorts_arrival"],
input[data-name="itineraryPorts_departureTime"],
input[data-name="itineraryPorts_arrivalTime"] {
  padding-left: 35px;
}

.choose-user-select {
  width: 350px;
}

.select-filters,
.filter-daterange {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.filter-select,
.filter-daterange > * {
  width: 100%;
}
