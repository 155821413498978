.pack-list {
  display: flex;
  gap: 20px;
}

.pack-card {
  border-radius: 50px !important;
  width: 250px !important;
  height: 350px;
  background-color: #ffffff;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  transition: background-color 0.3s ease, transform 0.8s ease, color 0.3s ease !important;
}

.pack-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  padding: 10px;
}

.pack-title {
  font-weight: bold !important;
  font-size: 22px !important;
}


.pack-content {
  font-size: 22px !important;
}

.pack-price {
  font-size: 40px !important;
}

.pack-card:hover {
  cursor: pointer;
  transform: translateY(-25px);
  background-color: #1f2447;
  color: #ffffff;
}

.pack-btn {
  background-color: #22638f !important;
  border-radius: 20px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  border: none !important;
}

.modal-dialog {
  place-content: center;
}
